<template>
  <div class="story-book pb-10">
    <v-container>
      <h1>Testing Environment</h1>
    </v-container>
    <!-- #1 https://codepen.io/GreenSock/pen/pojzxwZ -->
    <RevealScroll />

    <v-divider class="mb-5" />

    <!-- #2 https://codepen.io/GreenSock/pen/DzXpme -->
    <HomePageAnimation />
  </div>
</template>

<script>
import RevealScroll from "@/views/StoryBook/RevealScroll";
import HomePageAnimation from "@/views/StoryBook/HomePageAnimation";

export default {
  components: {
    RevealScroll,
    HomePageAnimation,
  },
};
</script>
