<template>
  <!-- https://codepen.io/GreenSock/pen/DzXpme -->
  <div class="homePageAnimation">
    <v-container>
      <h2>#2 CodePen Home GreenSock Home Page Animation - GSAP 3</h2>
    </v-container>
    <div>
      <div id="featureBox"></div>
      <div
        style="
          position: relative;
          width: 936px;
          height: 0px;
          overflow: visible;
          z-index: 20;
        "
        id="violator"
      >
        <img
          src="//greensock.com/_img/HTML5_corner_banner.png"
          alt="HTML5"
          style="position: absolute; top: -5px; right: -5px"
        />
      </div>
      <div class="feature" id="featureAnimation">
        <div id="featureBackground" style="pointer-events: none"></div>
        <div class="featureTextWhite" id="whyGSAP">Why GSAP?</div>
        <div class="featureTextGreen" id="performance">Performance</div>
        <div class="featureTextWhite" id="compatibility">Compatibility</div>
        <div id="browserIcons">
          <img
            src="//greensock.com/_img/browser-chrome-big.png"
            width="82"
            height="80"
          />
          <img
            src="//greensock.com/_img/browser-safari-big.png"
            width="77"
            height="86"
          />
          <img
            src="//greensock.com/_img/browser-ie-big.png"
            width="82"
            height="74"
          />
          <img
            src="//greensock.com/_img/browser-firefox-big.png"
            width="82"
            height="83"
          />
          <img
            src="//greensock.com/_img/browser-opera-big.png"
            width="69"
            height="75"
          />
        </div>
        <div class="featureTextMinor" id="fallDown">
          Other tools fall down in older browsers, but GSAP is remarkably
          compatible.
        </div>
        <img
          id="anythingIcon"
          src="//greensock.com/_img/animate-anything.png"
          width="102"
          height="108"
          style="position: absolute; top: 50px; left: 143px; display: block"
        />
        <div
          id="transformBox"
          style="
            background-color: #91e600;
            width: 130px;
            height: 130px;
            position: absolute;
            top: 40px;
            left: 100px;
          "
        ></div>
        <div
          class="featureTextWhite"
          id="transform"
          style="
            font-size: 34px;
            width: 640px;
            text-align: left;
            left: 270px;
            top: 80px;
            line-height: 36px;
          "
        >
          Scale, rotate & move independently
        </div>
        <div
          class="featureTextWhite"
          id="transformSub"
          style="
            font-size: 16px;
            width: 420px;
            text-align: left;
            left: 270px;
            top: 122px;
            color: #cccccc;
          "
        >
          (impossible with CSS animations/transitions)
        </div>
        <div
          class="featureTextWhite"
          id="anything"
          style="width: 620px; text-align: left; left: 302px; top: 63px"
        >
          XNJYHQLJYQEW
        </div>
        <div
          class="featureTextWhite"
          id="anythingSub"
          style="
            font-size: 16px;
            width: 390px;
            text-align: left;
            left: 300px;
            top: 122px;
            color: #cccccc;
          "
        >
          CSS, SVG, canvas libraries, colors, beziers, etc.
        </div>
        <div
          class="featureTextWhite"
          id="control"
          style="text-align: left; top: 64px; left: 130px"
        >
          Total control
        </div>
        <div
          class="featureTextWhite"
          id="controlSub"
          style="
            font-size: 16px;
            text-align: left;
            left: 130px;
            top: 122px;
            color: #cccccc;
          "
        >
          pause(), play(), reverse(), or timeScale() any tween or sequence.
        </div>

        <div class="featureTextWhite" id="GSAP" style="top: 60px">GSAP</div>
        <div
          class="featureTextMinor"
          id="newStandardText"
          style="top: 125px; text-align: center; width: 936px"
        >
          The new standard for HTML5 animation
        </div>
        <div
          id="featureClick"
          style="position: absolute; width: 936px; height: 220px"
        >
          <img src="//greensock.com/_img/blank.gif" width="936" height="220" />
        </div>
        <div
          id="replay"
          style="
            text-align: right;
            position: absolute;
            left: 825px;
            top: 190px;
            cursor: pointer;
            color: #999999;
            font-family: 'Lucida Grande', 'Lucida Sans Unicode', Helvetica,
              Arial, Verdana, sans-serif;
            font-size: 12px;
            width: 100px;
            visibility: hidden;
          "
        >
          replay
          <img
            id="replayIcon"
            src="//greensock.com/_img/codepen/allDevicesBanner/replay.png"
            width="19"
            height="19"
            style="margin-left: 2px; vertical-align: middle"
          />
        </div>
        <div id="ctrl_slider"></div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  methods: {
    animateFrom(elem, dir) {
      const direction = dir || 1;
      let x = 0;
      let y = direction * 100;
      const element = elem;
      if (element.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = 0;
      } else if (element.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 0;
      }
      element.style.transform = `translate(${x}px, ${y}px)`;
      element.style.opacity = "0";
      gsap.fromTo(
        element,
        { x, y, autoAlpha: 0 },
        {
          duration: 1.25,
          x: 0,
          y: 0,
          autoAlpha: 1,
          ease: "expo",
          overwrite: "auto",
        }
      );
    },

    hide(elem) {
      gsap.set(elem, { autoAlpha: 0 });
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach((elem) => {
      console.log("toArray");
      this.hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: () => {
          console.log("onEnter");
          this.animateFrom(elem);
        },
        onEnterBack: () => {
          console.log("onEnterBack");

          this.animateFrom(elem, -1);
        },
        onLeave: () => {
          console.log("onLeave");

          this.hide(elem);
        }, // assure that the element is hidden when scrolled into view
      });
    });
  },
};
</script>

<style scoped>
/* #1 */
body {
  font-weight: 300;
}
.ipsType_right {
  text-align: right;
}
.ipsType_center {
  text-align: center;
}
.cInnerContent {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}
.ipsSpacer_bottom_double {
  margin-bottom: 30px;
}
.ipsGrid {
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.ipsGrid::before,
.ipsGrid::after {
  display: table;
  content: "";
  line-height: 0;
}
.ipsGrid > [class*="ipsGrid_span"] {
  display: block;
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
}
.ipsGrid > .ipsGrid_span5 {
  width: 40.42553191489362%;
}
.ipsGrid > .ipsGrid_span7 {
  width: 57.44680851063829%;
}
.ipsGrid > [class*="ipsGrid_span"] {
  float: left;
  margin-left: 2%;
}
.ipsGrid > [class*="ipsGrid_span"]:first-child {
  margin-left: 0;
}
.feature {
  display: flex;
  align-items: center;
}
.card {
  margin-bottom: 30px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 1px 1px 5px 1px #cccccc;
  /*   transition: 0.3s; */
}
.featured-image-container .card {
  padding: 10px;
  height: 0;
  padding-bottom: calc(100% - 10px);
}
h2.heading_large {
  font-size: 1.8em;
}
img {
  max-width: 100%;
}

.header-section {
  margin: 200px auto;
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}

/* #2 */
body {
  background-color: #333;
  overflow: hidden;
}
#featureAnimation,
#featureBox {
  background-color: #000;
  height: 220px;
  overflow: hidden;
  line-height: normal;
  font-size: 80%;
}
#featureAnimation {
  position: relative;
  visibility: hidden;
  margin: 0 auto;
}
#featureBox {
  position: absolute;
}
#featureAnmation,
#featureBox,
#violator {
  left: 50%;
  transform: translate(-50%, 0);
}
#featureAnimation,
#featureBox,
#whyGSAP,
.featureTextGreen,
.featureTextWhite {
  width: 936px;
}
#whyGSAP,
.featureTextGreen,
.featureTextWhite {
  text-align: center;
}
#whyGSAP,
.featureTextGreen,
.featureTextWhite {
  font-size: 50px;
  position: absolute;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial, Verdana,
    sans-serif;
  top: 0;
}
.featureTextGreen {
  color: #91e600;
  font-weight: bold;
}
.featureTextWhite {
  color: white;
  font-weight: normal;
}
.star {
  position: absolute;
  width: 16px;
  height: 16px;
  display: none;
}
#browserIcons {
  top: 64px;
  left: 180px;
  width: 92px;
  height: 92px;
  position: absolute;
  text-align: left;
}
#browserIcons img {
  position: absolute;
}
.featureTextMinor {
  color: #cccccc;
  font-weight: normal;
  font-size: 20px;
  position: absolute;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial, Verdana,
    sans-serif;
  visibility: hidden;
}
.dot {
  position: absolute;
  background-color: #91e600;
}
#ctrl_slider {
  position: absolute;
  width: 824px;
  height: 10px;
  left: 18px;
  top: 196px;
  background: rgba(80, 80, 80, 0.3);
  border: 1px solid rgba(102, 102, 102, 0.5);
  visibility: hidden;
}
</style>
