<template>
  <div class="revealScroll">
    <v-container>
      <h2>
        #1 CodePen Home Reveal animations based on scroll direction - ScrollTrigger
      </h2>
    </v-container>
    <!-- #1 https://codepen.io/GreenSock/pen/pojzxwZ -->
    <div>
      <div class="cInnerContent">
        <h1 class="header-section gs_reveal ipsType_center">
          Scroll down and up to see different reveal animations
        </h1>

        <div class="features">
          <div
            class="
              feature
              ipsSpacer_bottom_double
              ipsGrid ipsGrid_collapsePhone
            "
          >
            <div
              class="
                featured-image-container
                ipsGrid_span5
                gs_reveal gs_reveal_fromLeft
              "
            >
              <div class="card">
                <img
                  width="479"
                  src="https://picsum.photos/479/479?index=1"
                  alt=""
                />
              </div>
            </div>

            <div class="ipsGrid_span7 ipsType_left">
              <h2 class="heading_large gs_reveal">
                Create amazing <strong>SVG</strong> animations
              </h2>
              <p class="gs_reveal">
                <a href="https://greensock.com/drawsvg/">DrawSVGPlugin</a>
                allows you to progressively reveal (or hide) SVG strokes to make
                them look like they're being drawn.
                <a href="https://greensock.com/morphsvg/">MorphSVGPlugin</a> to
                Morph any SVG shape into any other shape smoothly regardless of
                the number of points in each.
                <a href="/docs/v3/Plugins/MotionPathPlugin">MotionPathPlugin</a>
                allows you to easily move any object along a path.
              </p>
            </div>
          </div>

          <div
            class="
              feature
              ipsSpacer_bottom_double
              ipsGrid ipsGrid_collapsePhone
            "
          >
            <div class="ipsGrid_span7 ipsType_right">
              <h2 class="heading_large gs_reveal">
                Supercharge immersive <strong>WebGL</strong> experiences
              </h2>
              <p class="gs_reveal">
                GreenSock is used in some of the most popular
                <a href="//threejs.org/">Three.js</a> powered WebGL projects.
                Animate any object in a scene.
                <a href="https://greensock.com/PixiPlugin/">PixiPlugin</a> makes
                animating <a href="//www.pixijs.com/">Pixi.js</a> objects with
                GSAP a breeze. Animate position, scale, color effects and more
                with all the power and control of GSAP and the rendering speed
                of Pixi.js.
              </p>
            </div>

            <div
              class="
                featured-image-container
                ipsGrid_span5
                gs_reveal gs_reveal_fromRight
              "
            >
              <div class="card">
                <img
                  width="479"
                  src="https://picsum.photos/479/479?index=2"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            class="
              feature
              ipsSpacer_bottom_double
              ipsGrid ipsGrid_collapsePhone
            "
          >
            <div
              class="
                featured-image-container
                ipsGrid_span5
                gs_reveal gs_reveal_fromLeft
              "
            >
              <div class="card">
                <img
                  width="479"
                  src="https://picsum.photos/479/479?index=3"
                  alt=""
                />
              </div>
            </div>

            <div class="ipsGrid_span7 ipsType_left">
              <h2 class="heading_large gs_reveal">
                Control performant <strong>Canvas</strong> animations
              </h2>
              <p class="gs_reveal">
                GSAP makes animating with Canvas even easier by providing an
                easier way to look and sequence animations. GSAP works great
                with
                <a href="//www.adobe.com/products/animate.html"
                  >Adobe Animate</a
                >
                and <a href="//createjs.com/easeljs">EaseJS</a> through GSAP's
                <a href="https://greensock.com/easelplugin/">EaselJSPlugin</a>.
              </p>
            </div>
          </div>

          <div
            class="
              feature
              ipsSpacer_bottom_double
              ipsGrid ipsGrid_collapsePhone
            "
          >
            <div class="ipsGrid_span7 ipsType_right">
              <h2 class="heading_large gs_reveal">
                <strong>Award winning</strong> websites
              </h2>
              <p class="gs_reveal">
                GSAP is used on over 8,500,000 sites and over 1,000 sites
                featured on
                <a
                  href="https://www.awwwards.com/websites/gsap-animation/"
                  target="_blank"
                  >Awwwards</a
                >. For some of our favorites, check out
                <a href="https://greensock.com/showcase/">our showcase</a>.
              </p>
            </div>

            <div
              class="
                featured-image-container
                ipsGrid_span5
                gs_reveal gs_reveal_fromRight
              "
            >
              <div class="card">
                <img
                  width="479"
                  src="https://picsum.photos/479/479?index=4"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <header>
        <a href="https://greensock.com/scrolltrigger">
          <img
            class="greensock-icon"
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/16327/scroll-trigger-logo-light.svg"
            width="200"
            height="64"
          />
        </a>
      </header>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  methods: {
    animateFrom(elem, dir) {
      const direction = dir || 1;
      let x = 0;
      let y = direction * 100;
      const element = elem;
      if (element.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = 0;
      } else if (element.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 0;
      }
      element.style.transform = `translate(${x}px, ${y}px)`;
      element.style.opacity = "0";
      gsap.fromTo(
        element,
        { x, y, autoAlpha: 0 },
        {
          duration: 1.25,
          x: 0,
          y: 0,
          autoAlpha: 1,
          ease: "expo",
          overwrite: "auto",
        }
      );
    },

    hide(elem) {
      gsap.set(elem, { autoAlpha: 0 });
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach((elem) => {
      this.hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: () => {
          this.animateFrom(elem);
        },
        onEnterBack: () => {
          this.animateFrom(elem, -1);
        },
        onLeave: () => {
          this.hide(elem);
        }, // assure that the element is hidden when scrolled into view
      });
    });
  },
};
</script>

<style scoped>
body {
  font-weight: 300;
}
.ipsType_right {
  text-align: right;
}
.ipsType_center {
  text-align: center;
}
.cInnerContent {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}
.ipsSpacer_bottom_double {
  margin-bottom: 30px;
}
.ipsGrid {
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.ipsGrid::before,
.ipsGrid::after {
  display: table;
  content: "";
  line-height: 0;
}
.ipsGrid > [class*="ipsGrid_span"] {
  display: block;
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
}
.ipsGrid > .ipsGrid_span5 {
  width: 40.42553191489362%;
}
.ipsGrid > .ipsGrid_span7 {
  width: 57.44680851063829%;
}
.ipsGrid > [class*="ipsGrid_span"] {
  float: left;
  margin-left: 2%;
}
.ipsGrid > [class*="ipsGrid_span"]:first-child {
  margin-left: 0;
}
.feature {
  display: flex;
  align-items: center;
}
.card {
  margin-bottom: 30px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 1px 1px 5px 1px #cccccc;
  /*   transition: 0.3s; */
}
.featured-image-container .card {
  padding: 10px;
  height: 0;
  padding-bottom: calc(100% - 10px);
}
h2.heading_large {
  font-size: 1.8em;
}
img {
  max-width: 100%;
}

.header-section {
  margin: 200px auto;
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}
</style>
